import React, { memo, useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Divider, CircularProgress } from '@mui/material';
import { Element, scroller } from 'react-scroll';
import PostItem from './item.post';
import PostContentComponent from './postContent.component';
const ContentList: React.FC = () => {
    const [items, setItems] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        loadMoreItems(); // Load initial items
    }, []);

    const loadMoreItems = () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);

        // Simulate an API call with timeout
        setTimeout(() => {
            setItems((prevItems) => {
                const newItems = Array.from({ length: 10 }, (_, i) => `Item ${prevItems.length + i + 1}`);
                const updatedItems = [...prevItems, ...newItems];
                
                if (updatedItems.length >= 50) { // Stop loading after 50 items
                    setHasMore(false);
                }
                
                return updatedItems;
            });
            setIsLoading(false);
        }, 1000);
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight + 1 && hasMore && !isLoading) {
            loadMoreItems();
        }
    };

    return (
        <div style={{ overflowY: 'auto', height: '90vh' }} onScroll={handleScroll}>
            <List>
                <PostContentComponent />
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <PostItem />
                        <Divider component="li" />
                    </React.Fragment>
                ))}
                {isLoading && (
                    <Element name="loadingSpinner">
                        <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </ListItem>
                    </Element>
                )}
            </List>
        </div>
    );
};

export default memo(ContentList);
