import React from 'react';
import { Card, CardContent, Typography, Box, Stack, Divider, Avatar } from '@mui/material';
import PostImages from './post.images.component';

interface PostItemProps {
    item?: any
}

const PostItem = (props: PostItemProps) => {
    return (
        <Card sx={{ width: 'auto', margin: 'auto', mt: 5, boxShadow: 3,}}>
            <CardContent>
                <Stack spacing={2}>
                    
                    <Box sx={{ display: 'flex' }}>
                        <Avatar
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                            sx={{ width: 56, height: 56, marginRight: 2 }}
                        />
                        <Box>
                            <Typography variant="h6" component="div" gutterBottom>
                                Nguyễn Hưng
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                8h · Văn
                            </Typography>
                        </Box>
                    </Box>

                </Stack>
                <Box sx={{ height: '10px' }} />
                <PostImages images={[
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                    'https://scontent.fhan20-1.fna.fbcdn.net/v/t39.30808-6/466675525_1105136890984412_4510923575618028477_n.jpg?stp=dst-jpg_s1080x2048&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_eui2=AeHSBYXes_gRuLiAahSIPs3guKNAokqSONO4o0CiSpI406z3m2Ws3EbqjZeWPDdL9NVFuSWVHv-_bVsB4-FknCRn&_nc_ohc=MYTui2HKH0YQ7kNvgFzrHvR&_nc_zt=23&_nc_ht=scontent.fhan20-1.fna&_nc_gid=A_VUdlCeD8vXm0MViGXaB8O&oh=00_AYDR_ESt4ZZ3mKjdLQoAAq6QfqsvA8N1mLbBupGhwMlEtg&oe=673B4C3C',
                ]}  />
                {/* <img 
                    alt={'Game Development'}
                    style={{ objectFit: 'cover', width: '100%' }}
                    //width={"100px"}
                    src={require('../../../assets/images/background_auth.jpg')}
                /> */}
            </CardContent>
        </Card>
    );
};

export default PostItem;
